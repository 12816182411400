



























































import { Component, Vue } from "vue-property-decorator";

interface Magazine {
  img: string;
  subtitle: string;
  title: string;
  link: string;
}

@Component
export default class ProjectList extends Vue {
  dialog = false;
  selected = {} as Magazine;

  items: Magazine[] = [
    {
      img: require("@/assets/kahev-kadin-izi-2.jpg"),
      subtitle: "Sayı 2",
      title: "Şubat 2021",
      link: "https://online.fliphtml5.com/jsfjn/rytw/"
    },
    {
      img: require("@/assets/kahev-kadin-izi-1.jpg"),
      subtitle: "Sayı 1",
      title: "Ekim 2020",
      link: "https://online.fliphtml5.com/jsfjn/gbbr/"
    }
  ];

  openItem(item: Magazine) {
    this.dialog = true;
    this.selected = item;
  }
}
