









import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";
import List from "./List.vue";

@Component({
  metaInfo: {
    title: "Kadın İzi"
  },
  components: {
    Banner,
    List
  }
})
export default class ProjectsView extends Vue {}
